import React  from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY,AUTHENTICATED_EDIT_ENTRY } from 'configs/AppConfig'

const PublicRoute = () => {

	const { token } = useSelector(state => state.auth);
	console.log(localStorage.getItem(token));
    if(localStorage.getItem('signUp') === 'AUUZ##EFT'){
    	localStorage.removeItem('signUp');
    	return token ? <Navigate to={AUTHENTICATED_EDIT_ENTRY} /> : <Outlet/>
    }else{
    	return token ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet/>
    }

	
}

export default PublicRoute