import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH, HOME_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'apps.calendar',
        path: `${APP_PREFIX_PATH}/apps/calendar`,
        component: React.lazy(() => import('views/app-views/apps/calendar')),
    },
    {
        key: 'pages.setting',
        path: `${APP_PREFIX_PATH}/pages/setting/*`,
        component: React.lazy(() => import('views/app-views/pages/setting')),
    },
    {
        key: 'pages.profile',
        path: `${APP_PREFIX_PATH}/pages/profile`,
        component: React.lazy(() => import('views/app-views/pages/profile')),
    },
    {
        key: 'pages.pricing',
        path: `${APP_PREFIX_PATH}/pages/pricing`,
        component: React.lazy(() => import('views/app-views/pages/pricing')),
    },
    {
        key: 'pages.user-list',
        path: `${APP_PREFIX_PATH}/pages/patient-list`,
        component: React.lazy(() => import('views/app-views/pages/user-list')),
    },
    {
        key: 'pages.view-appointment',
        path: `${APP_PREFIX_PATH}/pages/appointment-view`,
        component: React.lazy(() => import('views/app-views/pages/view-appointment')),
    },
    {
        key: 'pages.start-appointment',
        path: `${APP_PREFIX_PATH}/pages/appointment-start`,
        component: React.lazy(() => import('views/app-views/pages/start-appointment')),
    },
    {
        key: 'pages.gallery-list',
        path: `${APP_PREFIX_PATH}/pages/gallery-list`,
        component: React.lazy(() => import('views/app-views/pages/view-gallery')),
    },
    {
        key: 'pages.gallery-add',
        path: `${APP_PREFIX_PATH}/pages/add-gallery`,
        component: React.lazy(() => import('views/app-views/pages/add-gallery')),
    },
    {
        key: 'pages.payment-records-add',
        path: `${APP_PREFIX_PATH}/pages/payment-records-add`,
        component: React.lazy(() => import('views/app-views/pages/add-payment-records')),
    },
    {
        key: 'pages.details-view',
        path: `${APP_PREFIX_PATH}/pages/details-view`,
        component: React.lazy(() => import('views/app-views/pages/details-view')),
    },
    {
        key: 'pages.sleep-diary-view',
        path: `${APP_PREFIX_PATH}/pages/sleep-diary-view`,
        component: React.lazy(() => import('views/app-views/pages/sleep-diary-view')),
    }
]